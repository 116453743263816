import * as types from "../types/orders"

const INITIAL_STATE = {
  ordersList: [],
  ordersMetaData: {},
  productsList : [] ,
  statusCount: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RECEIVE_ORDERS:
      return {
        ordersList: [...action.payload.data],
        ordersMetaData : action.payload.meta
      }
    case types.RECEIVE_PRODUCTS:
      return {
        productsList: [...action.payload.data],
      }
    default:
      return state
  }
}
