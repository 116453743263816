import * as types from "../types/organizer_products";

const INITIAL_STATE = {
 
  organizerProductsMetaData: {},
  organizerProductsList: [],
 
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RECEIVE_ORGANIZER_PRODUCTS:
      return {
        ...state,
        organizerProductsMetaData: { ...action.payload.meta },
        organizerProductsList: [...action.payload.data],
      };

    default:
      return state;
  }
};
