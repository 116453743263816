export const RECEIVE_OCCASIONS_GROUPS = "RECEIVE_OCCASIONS_GROUPS";

export const RECEIVE_GROUP_ASSIGNED_OCCASIONS =
  "RECEIVE_GROUP_ASSIGNED_OCCASIONS";

export const RECEIVE_ARCHIVED_OCCASIONS_GROUPS =
  "RECEIVE_ARCHIVED_OCCASIONS_GROUPS";

export const RECEIVE_OCCASIONS = "RECEIVE_OCCASIONS";

export const RECEIVE_ARCHIVED_OCCASIONS = "RECEIVE_ARCHIVED_OCCASIONS";
