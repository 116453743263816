import * as types from "../types/orders"

const INITIAL_STATE = {
  ordersApprovalList: [],
  ordersApprovalMetaData: {},

}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RECEIVE_ORDERS_APPROVAL:
      return {
        ordersApprovalList: [...action.payload.data],
        ordersApprovalMetaData : action.payload.meta
      }
    case types.RECEIVE_PRODUCTS:
      return {
        productsList: [...action.payload.data],
      }
    default:
      return state
  }
}
