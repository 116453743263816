import * as types from "../types/products";

const INITIAL_STATE = {
  productsList: [],
  productsMetaData: {},
  //
  organizerProductsMetaData:{},
  organizerProductsList:[],
  //
  pendingProductsRequests: [],
  pendingProductsRequestsMetaData: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RECEIVE_PRODUCTS:
      return {
        ...state,
        productsMetaData: { ...action.payload.meta },
        productsList: [...action.payload.data],
      };
      case types.RECEIVE_ORGANIZER_PRODUCTS:
      return {
        ...state,
        organizerProductsMetaData: { ...action.payload.meta },
        organizerProductsList: [...action.payload.data],
      };
    case types.RECEIVE_PENDING_REQUESTS_PRODUCTS:
      return {
        ...state,
        pendingProductsRequestsMetaData: { ...action.payload.meta },
        pendingProductsRequests: [...action.payload.data],
      };
    default:
      return state;
  }
};
